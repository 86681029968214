import React, { useState } from 'react'

import Icon from 'react-icons-kit'


import { statsBars as stock } from 'react-icons-kit/icomoon/statsBars'
import { manWoman as clients } from 'react-icons-kit/icomoon/manWoman'
import { heart as wedding } from 'react-icons-kit/icomoon/heart'
import { Fade, Slide } from 'react-reveal'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import { Button, Card, Col, Container, Row } from 'react-bootstrap'

// import sneaker from '../../imgz/sneaker.jpg'
import evidence from '../../imgz/happy customers/evidence.jpg'
import diamondsmile from '../../imgz/happy customers/diamondsmile.jpeg'
import toutyest from '../../imgz/happy customers/tout_y_est.jpeg'


import Utils from '../../Global/Utils'

function HappyCustomers() {
    const ic_s = {
        color: "#2ccdf5", display: "block", textAlign: "center", marginBottom: "30px"
    }
    const [isOpen, setIsOpen] = useState(false);

    const handleShowModal = () => setIsOpen(!isOpen);

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (

        <>
        
            {/* -----------------------------------------BiG Title ----------------------------------------- */}


            <Container fluid className="dev pt-5   pb-5 border   bigTitle  " style={{ backgroundColor: Utils.skinBg1() }}  >
                <Row className="mt-4 px-5   partneranim_bg d-flex justify-content-between  ">
                    <Col md={10} className='mx-auto '>
                        <h1 style={{ fontSize: '50px' }} className="mb-0 pb-0 text-center " > Happy customers </h1>
                        <p className="p-0 w-50 w-sm-100 mx-auto text-center my-4 ps-5"> Our happy customers</p>
                    </Col>

                </Row>
            </Container>

 
            <div className='container-fluid  ' style={{ backgroundColor: Utils.skinBg1() }}>
                <div className='row mx-sm-auto' style={{ backgroundColor:  '#fff', padding:'70px' }}>

                    <div className='row mx-sm-auto    d-flex justify-content-between '>
                        <Carousel
                            responsive={responsive}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            infinite={true}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >

                            <Card style={{ width: '10rem' }}>
                                <Card.Img variant="top" src={diamondsmile} />
                                <Card.Body>
                                    <Card.Title style={{fontSize:'14px'}}> DiamondSmile</Card.Title>
                                    <Card.Text>
                                        
                                    </Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '10rem' }}>
                                <Card.Img variant="top" src={evidence} />
                                <Card.Body>
                                    <Card.Title>evidence</Card.Title>
                                    
                                </Card.Body>
                            </Card>
                            
                            <Card style={{ width: '10rem' }}>
                                <Card.Img variant="top" src={diamondsmile} />
                                <Card.Body>
                                    <Card.Title>DiamondSmile</Card.Title>
                                    <Card.Text>
                                          
                                    </Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Body>
                            </Card>
                            <Card style={{ width: '10rem' }}>
                                <Card.Img variant="top" src={toutyest} />
                                <Card.Body>
                                    <Card.Title>Tout Y Est</Card.Title>
                                    
                                </Card.Body>
                            </Card>
                            

                        </Carousel>
                    </div>
                </div>



            </div ></>
    )
}

export default HappyCustomers