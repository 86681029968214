import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Header from './Header'
import TitleSubTitle from './TitleSubTitle'
import ImagesVideoContents from './ImagesVideoContents'
import OtherFeatures from './OtherFeatures'
import Footer from '../../Global/footer/Footer'
import Vid1 from '../Vid1'

function NewHome() {
  return (<>
    <Container fluid className='bg-dark text-white newhome'>
      <Row className='bg-dark  h-80vh ' >
        {/* header */}
        <Header />
      </Row>
      {/* Title with dynamic contents */}

     

      <ImagesVideoContents />
      {/* <OtherFeatures /> */}
    </Container>
    <Footer />
  </>
  )
}

export default NewHome