import React, { useState , useEffect, useRef} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ReactPlayer from 'react-player';
import { Slide } from 'react-reveal';

function Vid1() {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    // Function to trigger the video to start
  const playVideo = () => {
    setIsPlaying(true);
  };


    useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              // When the video is in view, start playing
              setIsPlaying(true);
            } else {
              // When the video is out of view, stop playing
              setIsPlaying(false);
            }
          },
          {
            threshold: 1.0, // Play when 100% of the video is visible
          }
        );
    
        if (videoRef.current) {
          observer.observe(videoRef.current);
        }
    
        return () => {
          if (videoRef.current) {
            observer.unobserve(videoRef.current);
          }
        };
      }, []);


    return (
        <Container>
            <Row>
                <Col md={11} ref={videoRef}>
                
                    <ReactPlayer
                        url="https://www.youtube.com/watch?v=9TXcOYzqQ3E"
                        style={{ position: 'relative' }}
                        playing={isPlaying} 
                        muted
                        controls={false}  // Disable default YouTube controls
                        width="590px" 
                        style={{ 
                            position: 'absolute', // Override default relative positioning
                            top: '0', 
                            left: '0',
                            backgroundColor: 'transparent' // Remove default background
                        }}
                        height="390px"
                    />
                </Col>
            </Row>
            <Row className='d-none'>

                {!isPlaying ? (
                    <>
                        {/* Custom thumbnail */}
                        <img onClick={playVideo}
                            src="https://img.youtube.com/vi/mE3qTp1TEbg/maxresdefault.jpg"
                            alt="Video thumbnail"
                            style={{ width: '100%', height: '100%' }}
                        />
                        {/* Custom Play Button */}
                        {/* <button
                                onClick={playVideo}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src="/path/to/play-button-icon.png"
                                    alt="Play"
                                    style={{ width: '80px', height: '80px' }}
                                />
                            </button> */}
                    </>
                ) : (

                    <iframe width="600"
                        height="400" src="https://www.youtube.com/embed/OohFYbozBeo"
                        title="quick dev"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen></iframe>




                )}



            </Row>
        </Container>
    )
}

export default Vid1