import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ImageAndVideo from './ImageAndVideo'

function TitleSubTitle({ Title, SubTitle, bg }) {
    return (
        <Col md={12} className={`d-flex p-5 justify-content-center   ${bg} `} >
            <Row className='d-flex justify-content-center'>
                <Col md={12} className='d-flex justify-content-center'>
                    <p>
                        <h1>{Title}</h1>
                    </p>
                </Col>
                <Col md={8} className='d-flex mb-5   justify-content-center'>
                    <p>{SubTitle}</p>
                </Col>
                <ImageAndVideo />
                <Col md={8} className='d-flex mt-5 justify-content-center'>
                    <p>Dive into our rich repository of code resources and boost your projects with essential snippets, tools, and insights.</p>
                </Col>
            </Row>
        </Col>
    )
}

export default TitleSubTitle