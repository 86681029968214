import React, { useEffect } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css'; // Import the CSS theme you want

// Import the specific language syntax if needed
import 'prismjs/components/prism-java'; // For Java syntax highlighting

const CodeSnippet = ({ code }) => {
  useEffect(() => {
    Prism.highlightAll(); // This will highlight all code blocks in the component
  }, []);

  return (
    <pre>
      <code className="language-java">
        {code}
      </code>
    </pre>
  );
};

export default CodeSnippet;