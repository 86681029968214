import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Vid1 from '../Vid1'

import tutCode from '../../imgz/tutorialCode.PNG'
function ImageAndVideo() {
    return (
        <>
            {/* part pic and video */}
            <Col md={10} className='   code_section' style={{ height: '400px' }}>
                <Row >
                    <Col md={6} className='page'>
                        <div className='title'>
                            page.java
                        </div>
                        <img src={tutCode} width="100%" alt="Image from entity view"></img>
                    </Col>
                    <Col aria-colspanmd={6} style={{ position: 'relative', width: '595px', height: '395px' }}>
                    <Vid1/>
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default ImageAndVideo