import React from 'react'

import { Link, useNavigate } from 'react-router-dom';
import NavLinks from './NavLinks';

import { Icon } from 'react-icons-kit'
import { home } from 'react-icons-kit/icomoon/home'
import { compass } from 'react-icons-kit/icomoon/compass'

import { stack as deploy } from 'react-icons-kit/icomoon/stack'
import { ic_view_week_outline as struc } from 'react-icons-kit/md/ic_view_week_outline'
import { ic_credit_card as layout } from 'react-icons-kit/md/ic_credit_card'
import { ic_next_plan_twotone as more } from 'react-icons-kit/md/ic_next_plan_twotone'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from '../imgz/Logo.jpg'
import { useSignOut } from 'react-auth-kit';
import { useState } from 'react';
import { useEffect } from 'react';
import OtherStyles from '../Styles/OtherStyles';
import '../Styles/App.css'
import { ic_person_pin } from 'react-icons-kit/md/ic_person_pin'

import { ic_logout } from 'react-icons-kit/md/ic_logout'
import { ic_perm_phone_msg as phone } from 'react-icons-kit/md/ic_perm_phone_msg'
import { code } from 'react-icons-kit/fa/code'
import { ic_contact_page_outline as aboutus } from 'react-icons-kit/md/ic_contact_page_outline'
import { user } from 'react-icons-kit/icomoon/user'

import { Col, Row } from 'react-bootstrap';
import '../Navbar/Navbar.css'
import { color } from 'framer-motion';
import { BorderBottom, Padding } from '@mui/icons-material';
import { ic_location_on_outline as location } from 'react-icons-kit/md/ic_location_on_outline'
import { ic_email_outline as email } from 'react-icons-kit/md/ic_email_outline'
import { ic_facebook } from 'react-icons-kit/md/ic_facebook'
import { linkedin } from 'react-icons-kit/fa/linkedin'
import Utils from '../Global/Utils';

function SecondMenu({ visible }) {

    /* #region  Functions */

    const signOut = useSignOut()

    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [userType, setUserType] = useState('')

    const logout = () => {


        localStorage.clear()
        try {
            // signOut()
        } catch (err) {
            console.log('Error while loggin out' + err)
        }

        window.location.replace('/login');
        // navigate('/logout')
        // alert('done')

    }

    let my_class = "background-color: #2ccdf5;  ";
    const navLinks = {
        color: "#fff",
        textDecoration: "none",
        fontWeight: "bolder",

        fontSize: "14px",
        textShadow: "0px 0px 5px #000",
        marginLeft: "12px"
        , marginTop: '7px'
    };
    const nav_styles = {
        backgroundColor: "#1679a3",

        fontWeight: "bolder",
        zIndex: "1",
        textShadow: "0px 0px 5px #fff", BorderBottom: '1px solid #fff',

        /*To make it sticky*/
        position: '-webkit-sticky',
        position: 'sticky',
        zIndex: '7'

    };
    const companyBrand = {
         textShadow: 'none', color: '#fff'
    }
    const subnavLinks = {
        backgroundColor: "#092a32",
        fontWeight: "bolder",
        zIndex: "1",
        textShadow: "0px 0px 5px #fff",
        // borderBottom: 'rgb(76, 116, 128) 1px solid '
    }
    const loginLink = {
        color: "#000",
        textDecoration: "none",
        fontWeight: "bolder",

        fontSize: "13px"

    };
    const iconStyle = {
        color: "#2ccdf5",
        marginRight: Utils.SocialmediaiconSize

    };

    const brandStyles = {
        color: "#fff",
        textDecoration: "none",
        fontWeight: "bolder",

        fontSize: "20px", textShadow: "0px 0px 5px #000"
    }
    const subMenuTitle = {
        name: "<span style={{color:'#000'}}>Action</span>"
    }

    const changePassword = () => {
        // navigate('/changepassword')
    }
    useEffect(() => {
        const theusername = localStorage.getItem('token')
        const theUserType = localStorage.getItem('catname')
        if (theusername) {
            setUsername(localStorage.getItem('token'))
        }
        if (theUserType) {
            setUserType(localStorage.getItem('catname'))
        }
        document.body.style.backgroundColor = Utils.skinBg1();
        // alert(username)
    }, [])

    const sticked = {
        position: '-webkit-sticky',
        position: 'sticky', top: '0', zIndex: '7'
    }
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleMenu=()=>{
        setIsCollapsed(!isCollapsed)
    }
    const hideSubmenu = () => {
        setIsCollapsed(false);
    };

    /* #endregion */
    return (
        <>
            {(!username) &&
                <Container sticky="top" style={sticked} fluid className='customBarContainer '>
                    <Row className="d-flex justify-content-between customBar align-items-center ">
                        <Col md={8} sm={6}  >
                            <Row>
                                <Col className='d-none d-lg-block'>
                                    <Icon style={iconStyle} size={20} icon={location} />
                                    KN 3 RD – Prince House Bus Stop, 2nd Floor
                                </Col>
                                <Col>
                                    <Icon style={iconStyle} size={18} icon={email} />
                                    info@codeguru-pro.com
                                </Col>

                            </Row>
                        </Col>
                        <Col md={4} sm={6}>
                            <Row  >
                                <Col md={8} sm={8} >
                                    <Icon className='shaking' style={iconStyle} size={18} icon={phone} />
                                    +250 784 113 888</Col>
                                <Col sm={4}>
                                    <a target="_blank" href="https://web.facebook.com/profile.php?id=100066522506361">
                                        <Icon style={iconStyle} size={18} icon={ic_facebook} />
                                    </a>
                                    <a target="_blank" href="http://www.linkedin.com/in/sangwa-emmanuel-23295b97">
                                        <Icon style={iconStyle} size={18} icon={linkedin} />
                                    </a>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            }
            <Navbar sticky="top" className={`navbar custom-navbar p-0 ${visible ? 'visible' : 'hidden'}`} collapseOnSelect expand="lg" style={nav_styles} >
                <Container >
                    <a href="/"  > <div className='logo'>       </div>     </a>
                    {!username && (<Navbar.Brand className="nav-brand" style={companyBrand} href="/">CODEGURU</Navbar.Brand>)}

                    <Navbar.Toggle onClick={toggleMenu}  aria-controls="responsive-navbar-nav " />
                    <Navbar.Collapse id="responsive-navbar-nav" in={!isCollapsed}>
                        <Nav className="me-auto">
                            {(!username) && <>
                                <Nav.Link onClick={toggleMenu} style={navLinks} as={Link} to="/codeguru-mission">
                                    <Icon style={iconStyle} size={18} icon={struc} />
                                    Mission
                                </Nav.Link>
                                <Nav.Link  onClick={toggleMenu}   style={navLinks} as={Link} to="/services">
                                    <Icon style={iconStyle} size={18} icon={struc} />
                                    Services
                                </Nav.Link>
                            </>
                            }
                            {(username !== '' && username !== null) && <>
                                <Nav.Link  onClick={toggleMenu}  style={navLinks} as={Link} to="/tutorials">
                                    <Icon style={iconStyle} size={18} icon={struc} />
                                    Tutorials
                                </Nav.Link>
                            </>
                            }

                        </Nav>

                        {(username != '' && username != null) &&
                            <Nav className="me-auto">
                                <Nav.Link  onClick={toggleMenu}   style={navLinks} as={Link} to="/dashboard">
                                    <Icon style={iconStyle} size={18} icon={compass} />
                                    Dashoard
                                </Nav.Link>
                                <Nav.Link onClick={toggleMenu}  style={navLinks} as={Link} to="/codereader">
                                    <Icon style={iconStyle} size={18} icon={code} />
                                    Code Reader
                                </Nav.Link>
                                <NavDropdown className='subMenu' color='#fff'
                                    title={
                                        <span style={navLinks}>
                                            <Icon style={iconStyle} size={18} icon={more} />
                                            Basic Crud
                                        </span>
                                    } id="collasible-nav-dropdowns">
                                    <NavDropdown.Item className='subMenulink' style={{ marginTop: '25px' }} as={Link} to="/structure">Sub 1 </NavDropdown.Item>
                                    <NavDropdown.Item className='subMenulink' as={Link} to="/unit">      Another action      </NavDropdown.Item>
                                    <NavDropdown.Item className='subMenulink' as={Link} to="/unit">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item className='subMenulink' as={Link} to="/tuple">
                                        Separated link
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>


                        }
                        {(username == '' || username == null) && <>
                            <Nav.Link  onClick={toggleMenu} style={navLinks} as={Link} className='mx-2' to="/contactus">
                                <Icon style={iconStyle} size={18} icon={phone} />
                                Contact us
                            </Nav.Link>
                            {/* <Nav.Link style={navLinks} as={Link} to="/aboutus">
                                <Icon style={iconStyle} size={18} icon={aboutus} />
                                About us
                            </Nav.Link> */}
                        </>
                        }
                        <Nav>


                            {userType ?
                                <NavDropdown className='menuDropdown' style={{ marginTop: '10px' }} color='#fff' title={
                                    <span style={navLinks} >
                                        <Icon style={OtherStyles.iconStyles()} icon={ic_person_pin} size={24} />
                                        {localStorage.getItem('catname') !== null ? localStorage.getItem('catname') : 'Login'}

                                    </span>
                                } id="collasible-nav-dropdowns">
                                    <NavDropdown.Divider />

                                    <>
                                        <Nav.Link  as={Link} onClick={logout}>
                                            <span style={{ color: '#000', fontWeight: 'bolder' }}>
                                                <Icon style={OtherStyles.iconStyles()} size={22} icon={ic_logout} />
                                                Logout
                                            </span>
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/changepassword" onClick={changePassword}>
                                            <span style={{ color: '#000', fontWeight: 'bolder' }}>
                                                <Icon style={OtherStyles.iconStyles()} size={22} icon={ic_logout} />
                                                Change Password
                                            </span>
                                        </Nav.Link>
                                        <Nav.Link as={Link} to="/users" onClick={changePassword}>
                                            <span style={{ color: '#000', fontWeight: 'bolder' }}>
                                                <Icon style={OtherStyles.iconStyles()} icon={ic_person_pin} size={24} />
                                                Users
                                            </span>
                                        </Nav.Link>
                                    </>

                                </NavDropdown>

                                :''
                            // <Nav.Link  onClick={toggleMenu}   style={navLinks} as={Link} to="/login">
                            //        <Icon style={OtherStyles.iconStyles()} size={22} icon={deploy} />
                            //         Login 
                            // </Nav.Link>
                            
                                }
                        </Nav>







                    </Navbar.Collapse>
                </Container>
            </Navbar >


        </>
    )
}

export default SecondMenu