import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TitleSubTitle from './TitleSubTitle'

function ImagesVideoContents() {
  return (
    <>

      <Row>

        {/* Title with dynamic contents */}
        <TitleSubTitle bg='dark' Title="Deliver your Apps easily and fast "
          SubTitle="Deploy your applications easily and quickly with our streamlined
         tools and resources, designed to accelerate your development process and ensure
         smooth delivery" />

      </Row>

      <Row className='d-none' >
        {/* Title with dynamic contents */}
        <TitleSubTitle bg='darker' Title="Deploy with Ease "
          SubTitle="Deploy your applications easily and quickly with our 
        streamlined tools and resources, designed to accelerate your 
        development process and ensure smooth delivery" />
      </Row>
      <Row className='d-none' >
        {/* Title with dynamic contents */}
        <TitleSubTitle bg='dark' Title="Streamline Your Delivery Process "
          SubTitle="Streamline your app delivery process with our easy-to-use
         tools and resources, designed to get your applications up and running 
         in minutes. Say goodbye to complexity and hello to efficiency!" />
      </Row>
      <Row className='d-none'>
        {/* Title with dynamic contents */}
        <TitleSubTitle bg='darker' Title="Maximize Your Productivity "
          SubTitle="Maximize your productivity with our user-friendly tools that
         make app deployment a breeze. Developers can now launch their projects
          within minutes, reducing complexity and focusing on what truly matters—building 
          great apps." />
      </Row>
      <Row className='d-none'>
        {/* Title with dynamic contents */}
        <TitleSubTitle bg='dark' Title="Get to Market Faster"
          SubTitle="Empower your development process with our streamlined tools
         that ensure you can deliver your apps swiftly and efficiently. Our resources are designed to minimize hassle and maximize speed, helping you go from concept to launch in just minutes." />
      </Row>

      <Row className='Best_tech_stack  d-flex align-items-stretch justify-content-center'>
        <Col md={3} className=''>
          <div >
            <h5>Programming Languages (Core)</h5>
            <ul>
              <li>Java (Spring Boot, JPA)</li>
              <li>Python (Django, Flask)</li>
              <li>JavaScript/TypeScript (Node.js, Express)</li>
              <li>Ruby (Ruby on Rails)</li>
              <li>PHP (Laravel, Symfony)</li>
              <li>Go (Gin, Fiber)</li>
              <li>C# (ASP.NET)</li>
            </ul>
          </div>
        </Col>

        <Col md={3}>
          <div >
            <h5>Web Frameworks</h5>
            <ul>
              <li>Spring Boot, Quarkus (Java)</li>
              <li>Django, Flask (Python)</li>
              <li>Express.js, NestJS (Node.js)</li>
              <li>Laravel, Symfony (PHP)</li>
              <li>Ruby on Rails (Ruby)</li>
              <li>Gin, Fiber (Go)</li>
            </ul>
          </div>
        </Col>

        <Col md={3}>
          <div >
            <h5>Database Management</h5>
            <ul>
              <li>SQL: PostgreSQL, MySQL, SQL Server, SQLite</li>
              <li>NoSQL: MongoDB, Cassandra, Redis, Couchbase</li>
              <li>ORMs: Hibernate, SQLAlchemy, Sequelize, Eloquent</li>
            </ul>
          </div>
        </Col>

        <Col md={3}>
          <h5>API Design and Development</h5>
          <ul>
            <li>RESTful APIs</li>
            <li>GraphQL</li>
            <li>gRPC</li>
            <li>OAuth2, JWT, OpenID Connect</li>
            <li>API Documentation: Swagger/OpenAPI</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Authentication & Authorization</h5>
          <ul>
            <li>JWT (JSON Web Tokens)</li>
            <li>OAuth2 / OpenID Connect</li>
            <li>Session Management</li>
            <li>Role-Based Access Control (RBAC)</li>
            <li>SAML</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Cloud and Infrastructure</h5>
          <ul>
            <li>AWS, GCP, Azure</li>
            <li>Serverless Architectures: AWS Lambda, Google Cloud Functions</li>
            <li>Containers and Orchestration: Docker, Kubernetes</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Caching Mechanisms</h5>
          <ul>
            <li>Redis</li>
            <li>Memcached</li>
            <li>Varnish</li>
            <li>HTTP Caching</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Message Queuing & Streaming</h5>
          <ul>
            <li>RabbitMQ, Kafka, SQS</li>
            <li>Stream Processing: Kafka Streams, AWS Kinesis</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Testing</h5>
          <ul>
            <li>Unit Testing: JUnit, PyTest, Mocha</li>
            <li>Integration Testing</li>
            <li>Load Testing: JMeter, Gatling</li>
            <li>Test Automation: Selenium, TestNG</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>DevOps & CI/CD</h5>
          <ul>
            <li>CI/CD Pipelines: Jenkins, GitLab CI</li>
            <li>Infrastructure as Code: Terraform, CloudFormation</li>
            <li>Monitoring: ELK Stack, Prometheus, Grafana</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Security</h5>
          <ul>
            <li>Data Encryption: SSL/TLS, HTTPS</li>
            <li>Vulnerabilities: OWASP Top 10</li>
            <li>Web Application Firewalls (WAF)</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Microservices Architecture</h5>
          <ul>
            <li>Service Discovery: Eureka, Consul</li>
            <li>Service Mesh: Istio, Linkerd</li>
            <li>API Gateway: Kong, NGINX</li>
            <li>Circuit Breakers: Hystrix, Resilience4j</li>
            <li>Event-Driven Architecture: Kafka, RabbitMQ</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Scaling and Performance</h5>
          <ul>
            <li>Load Balancing: NGINX, HAProxy</li>
            <li>CDNs: Cloudflare, Akamai</li>
            <li>Auto-scaling in the Cloud</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Data Structures and Algorithms</h5>
          <ul>
            <li>Arrays, Linked Lists, Trees, Graphs</li>
            <li>Sorting, Searching, Dynamic Programming</li>
            <li>Complexity Analysis: Big O notation</li>
          </ul>
        </Col>

        <Col md={3}>
          <h5>Soft Skills</h5>
          <ul>
            <li>Problem-Solving</li>
            <li>Communication</li>
            <li>Time Management</li>
            <li>Continuous Learning</li>
          </ul>
        </Col>
      </Row>


    </>
  )
}

export default ImagesVideoContents