import React from 'react'
import { Col, Row } from 'react-bootstrap'

import code from '../../imgz/logo/Logo_400.png'

function Header() {
    return (
        <Col md={12}  className="d-flex justify-content-center align-items-center  ">
            <Row>

                <Col md={12} className=' m-sm-4 d-flex justify-content-center  '>
                    <p >
                        <img width="100" alt="Loading" src={code} />
                    </p>
                </Col>

                <Col md={12} className='   d-flex justify-content-center  hhome  '>
                    <p >
                        <h1 >Developers</h1>
                    </p>
                </Col>
                <Col md={12} className='  d-flex justify-content-center  '>

                    <Row>
                        <Col md={12} className=''>
                            <Row className='d-flex justify-content-center  align-items-start text-white'  >
                                <Col md={12} className=''>
                                    <p className='phome'>
                                        Explore our extensive collection of code resources
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Col>


                <Row>
                    <Col md={12} className='d-flex  justify-content-center '>

                        <Col md={12} lg={12} className='d-flex justify-content-center align-items-start   '>
                            <Row>
                                <Col md={6} lg={6} className='  '>
                                    <p className='m-0'>
                                        <a className='btn text-dark rounded active' href="/tutdetails">Learn More</a>
                                    </p>

                                </Col>

                                <Col  md={6} lg={6}  className='  '>
                                    <p className='m-0 '>
                                        <a style={{width: '200px'}} className='btn   round round-lg-2' href="/contactus">Need to talk to us ?</a>
                                    </p>

                                </Col>

                            </Row>



                        </Col>
                    </Col>
                </Row>
            </Row>
        </Col>

    )
}

export default Header