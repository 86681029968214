import React, { useEffect, useRef } from 'react'
import { Card, Col, Container, ListGroup, Nav, Row, Tab } from 'react-bootstrap';
// import { Helmet } from 'react-helmet';

import './Tutorials.css'
// import Footer from '../Global/Footer/Footer';
import { Icon } from 'react-icons-kit'
import { ic_code as code } from 'react-icons-kit/md/ic_code'
import DockerContent from './DockerContent';
import Cicd from './Cicd/Cicd';
import { Helmet } from 'react-helmet';
import Whatsapp from '../pages/WhatsappLink/Whatsapp';
import Utils from '../Global/Utils';
import Microfrontend from './microfrontend/Microfrontend';
import MailCow from './MailCow/MailCow';
import CodeSnippet from './Introduction/CodeSnippet';
import { Content } from './Introduction/Content';
import NewHome from './NewHome/NewHome';
import './NewHome/NewHome.css'
function TutorialsHome() {

    useEffect(() => {
        Utils.PageLoadeDefaults()
        // document.body.classList.add('dockerBody')

    }, [])
    return (
        <>
            <Helmet>
                <title>Tutorials - CODEGURU Systems</title>
                <meta name="description" content="Tutorials - CODEGURU" />
                <meta name="keywords" content="Java programming, Java development, Java tutorials, Java guides, 
                Java code examples, Java best practices, Java frameworks, Spring framework, Hibernate, Java EE, 
                Java SE, JavaFX, Java Swing, Java concurrency, Java multithreading, Java streams, Java collections,
                 Java design patterns, Java debugging, Java performance tuning, Java security, Java testing, JUnit, 
                 Mockito, Java web development, Java RESTful services, Java microservices, Java deployment, Java build tools,
                  Maven, Gradle, Java IDE, IntelliJ IDEA, Eclipse, NetBeans, Java garbage collection, Java memory management, 
                  Java annotations, Java lambda expressions, Java functional programming, Java 8, Java 11, Java 17, DevOps, 
                  DevOps tools, CI/CD, continuous integration, continuous delivery, Jenkins, Git, GitHub, GitLab, Docker,
                   Kubernetes, containerization, infrastructure as code, Terraform, Ansible, Puppet, Chef, monitoring,
                    Prometheus, Grafana, DevOps best practices, DevOps automation, cloud computing, AWS, Azure, Google Cloud,
                     cloud deployment, DevOps pipelines, build automation, deployment automation, configuration management, 
                     DevOps culture, DevOps practices, ReactJS, React development, React tutorials, React guides,
                      React components, React hooks, React state management, React context API, Redux, React Router, 
                      React performance optimization, React testing, Jest, Enzyme, React best practices, 
                      React patterns, React design patterns, React project structure, React debugging, React dev tools, 
                      React Native, React and TypeScript, React and GraphQL, React lifecycle methods,
                       React functional components, React class components, React props, React state, React context,
                        JSX, React virtual DOM, single-page applications, front-end development, web development with React"/>

            </Helmet>
            <Whatsapp />

            <NewHome />

            <Container fluid className='mt-5 d-none ' >

                <Tab.Container id="left-tabs-example border  " style={{ position: 'sticky', top: '0' }} defaultActiveKey="first">
                    <Row>
                        <Col sm={2}>

                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Introduction</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Docker</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">CI/CD</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="four">Micro FrontEnd</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="five">MailCow</Nav.Link>
                                </Nav.Item>
                            </Nav>

                        </Col>
                        <Col sm={9} >
                            <Tab.Content>
                                <Tab.Pane eventKey="first" style={{ backgroundColor: '#fff' }}>
                                    <Content />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" style={{ backgroundColor: '#fff' }}>
                                    <DockerContent />

                                </Tab.Pane>
                                <Tab.Pane eventKey="third" style={{ backgroundColor: '#fff' }}>
                                    <Cicd />
                                </Tab.Pane>
                                <Tab.Pane eventKey="four" style={{ backgroundColor: '#fff' }}>
                                    <Microfrontend />
                                </Tab.Pane>
                                <Tab.Pane eventKey="five" style={{ backgroundColor: '#fff' }}>
                                    <MailCow />
                                </Tab.Pane>

                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
            {/* <Footer /> */}
        </>
    )
}

export default TutorialsHome