import React, { useRef, useEffect } from "react"
import TutorialsContent from "./TutorialsContentSample"
import journey_coder from '../../imgz/another guy standing.webp'
export const Content = () => {


    const canvasRef = useRef(null);

    return (
        <>

            
            <div>
                <h1> Journey to Software Development</h1>
                <img width="100%" src={journey_coder}/>

                <h1>Starting with HTML and CSS</h1>
                <p>
                    My journey in software development began with a deep curiosity
                    and passion for technology. I started by diving into the
                    fundamentals of web development with HTML and CSS,
                    which laid the foundation for my skills as a frontend designer.
                </p>
                <h2>Overcoming Challenges with Bootstrap</h2>
                <p>
                    Initially, I faced challenges adapting to frameworks like Bootstrap,
                    but with perseverance and continuous practice,
                    I mastered it and incorporated it into my projects seamlessly.
                </p>
                <h2>Transition to JSP and jQuery</h2>
                <p>
                    As I progressed, I became proficient in JSP and jQuery,
                    which allowed me to create dynamic and interactive web applications.
                </p>
                <h2>Embracing Spring Boot and ReactJS</h2>
                <p>
                    However, I realized the need to stay current with industry trends and technologies.
                    This led me to embrace Spring Boot for backend development and ReactJS for building modern,
                    component-based user interfaces. Each step in this journey was marked by a willingness
                    to adapt and a courage to tackle new technologies head-on.
                </p>
                <h2>Versatility in Full-Stack Development</h2>
                <p>
                    Over the years, I've had the opportunity to work on a variety of applications, each
                    presenting unique challenges and requiring different technological solutions.
                    This experience has allowed me to gain proficiency in a
                    diverse set of tools and frameworks, making me a versatile full-stack developer.
                </p>
                <h1>Recommendations for Beginners</h1>
                <p>
                    On programming languages, I tried to pick some of the best ones. I encourage anyone to
                    first pick one and go with it. A great programmer does not need to start with multiple
                    programming languages;
                    rather, they need to become proficient in one first. Here i only ppicked a few to choose from between Python/Java/PHP .
                </p>
                <ul>
                    <ol><h2>JavaScript </h2></ol>
                    <ul>
                        <li>
                            Array Manipulation: Common operations like filtering, mapping, and reducing arrays.
                        </li>
                        <li>DOM Manipulation: Adding, removing, and modifying HTML elements.</li>
                        <li>Event Handling: Attaching and managing event listeners.</li>
                        <li>Fetch API: Making HTTP requests using the fetch API.</li>
                        <li>Form Validation: Simple client-side form validation techniques.</li>
                    </ul>
                    <ol><h2>Java (Spring Boot)</h2></ol>
                    <ul>
                        <li>File I/O: Reading from and writing to files.</li>
                        <li>Collections: Using lists, sets, and maps effectively.</li>
                        <li>Concurrency: Basic usage of threads and executors.</li>
                        <li>Networking: Simple examples of using HTTP clients.</li>
                        <li>Spring Boot: Creating RESTful APIs with Spring Boot.</li>
                    </ul>
                    <ol><h2>Python (FastAPI)</h2></ol>
                    <ul>
                        <li>File Handling: Reading from and writing to files.</li>
                        <li>Data Structures: Using lists, dictionaries, sets, and tuples.</li>
                        <li>Web Scraping: Basic web scraping with BeautifulSoup or Scrapy.</li>
                        <li>Data Analysis: Simple snippets using pandas and NumPy.</li>
                        <li>FastAPI: Building high-performance APIs with FastAPI.</li>
                    </ul>
                    <ol><h2>HTML/CSS</h2></ol>
                    <ul>
                        <li>Layouts: Flexbox and Grid examples for responsive design.</li>
                        <li>Forms: Custom form styling and validation.</li>
                        <li>Animations: CSS animations and transitions.</li>
                        <li>Responsive Design: Media queries for different screen sizes.</li>
                        <li>Typography: Best practices for font usage and styling.</li>
                    </ul>
                    <ol><h2>SQL</h2></ol>
                    <ul>
                        <li>CRUD Operations: Basic Create, Read, Update, Delete operations.</li>
                        <li>Joins: Examples of inner joins, outer joins, and self joins.</li>
                        <li>Indexes: Creating and using indexes effectively.</li>
                        <li>Transactions: Using transactions to manage database changes.</li>
                        <li>Aggregation: Using GROUP BY, HAVING, and aggregate functions.</li>
                    </ul>
                    <ol><h2>Android (Kotlin)</h2></ol>
                    <ul>
                        <li>Networking: Making HTTP requests with Retrofit or OkHttp.</li>
                        <li>Database: Using Room for local storage.</li>
                        <li>UI Components: Custom views, RecyclerView, and fragments.</li>
                        <li>Lifecycle Management: Handling activity and fragment lifecycle events.</li>
                        <li>Permissions: Requesting and handling runtime permissions.</li>
                    </ul>
                    <ol><h2>PHP (Laravel)</h2></ol>
                    <ul>
                        <li>Database Connection: Connecting to a MySQL database using Laravel's Eloquent ORM.</li>
                        <li>Form Handling: Processing form submissions.</li>
                        <li>File Upload: Handling file uploads securely.</li>
                        <li>Sessions and Cookies: Managing user sessions and cookies.</li>
                        <li>API Development: Simple REST API examples with Laravel.</li>
                    </ul>
                    <ol><h2>Shell Scripting</h2></ol>
                    <ul>
                        <li>File Operations: Basic file and directory operations.</li>
                        <li>Text Processing: Using grep, sed, and awk for text manipulation.</li>
                        <li>System Monitoring: Scripts to monitor system performance and logs.</li>
                        <li>Automation: Automating routine tasks with cron jobs.</li>
                    </ul>
                    <ol><h2>GIT</h2></ol>
                    <ul>
                        <li>Basic Commands: Cloning, committing, pushing, and pulling.</li>
                        <li>Branching: Creating, merging, and deleting branches.</li>
                        <li>Rebasing: Basic rebase operations.</li>
                        <li>Stashing: Stashing and applying changes.</li>
                        <li>Tagging: Creating and managing tags.</li>
                    </ul>
                    <ol><h2>DevOps (Docker, Kubernetes, Nginx)</h2></ol>
                    <ul>
                        <li>Docker: Writing Dockerfiles, building images, and running containers.</li>
                        <li>Kubernetes: Basic kubectl commands, creating deployments, and services.</li>
                        <li>CI/CD: Example configurations for Jenkins, Travis CI, or GitHub Actions.</li>
                        <li>Infrastructure as Code: Examples using Terraform or Ansible.</li>
                        <li>Nginx Load Balancing: Configuring Nginx as a load balancer.</li>
                    </ul>
                    <ol><h2>Database Load Balancing</h2></ol>
                    <ul>
                        <li>MySQL Load Balancing: Using ProxySQL or HAProxy to distribute database traffic across multiple MySQL instances.</li>
                        <li>PostgreSQL Load Balancing: Implementing Pgpool-II or Citus for PostgreSQL load balancing.</li>
                        <li>MongoDB Sharding: Configuring sharded clusters for MongoDB.</li>
                    </ul>
                    <ol><h2>React Best Practices, Animations, and Microfrontends</h2></ol>
                    <ul>
                        <li>Component Structure: Organizing components in a modular and reusable way.</li>
                        <li>State Management: Using hooks (useState, useReducer, useContext) and libraries like Redux or Zustand.</li>
                        <li>Effect Handling: Using useEffect for side effects and cleanup.</li>
                        <li>Prop Types: Using PropTypes or TypeScript for type checking.</li>
                        <li>Performance Optimization: Using React.memo, useCallback, and useMemo.</li>
                        <li>Animations: Creating smooth animations with Framer Motion.</li>
                        <li>Microfrontends: Implementing and orchestrating microfrontends using frameworks like Single-SPA or Module Federation.</li>
                    </ul>
                </ul>
                <h1>SAMPLE SNIPPETS</h1>
                <h2>Spring Boot REST API (Java)</h2>
                <TutorialsContent content={`
                    @RestController
                    @RequestMapping("/api")
                    public class ApiController {
                        @GetMapping("/hello")
                        public ResponseEntity<String> sayHello() {
                            return ResponseEntity.ok("Hello, World!");
                        }
                    }`}
                />
                <h2>FastAPI Simple Endpoint (Python)</h2>
                <TutorialsContent content={`
                    from fastapi import FastAPI

                    app = FastAPI()

                    @app.get("/hello")
                    def read_root():
                        return {"message": "Hello, World!"}`}
                />
                <h2>Laravel Route and Controller (PHP)</h2>
                <TutorialsContent content={`
                    // routes/web.php
                    Route::get('/hello', 'HelloController@index');

                    // app/Http/Controllers/HelloController.php
                    namespace App\Http\Controllers;

                    use Illuminate\Http\Request;

                    class HelloController extends Controller {
                        public function index() {
                            return response()->json(['message' => 'Hello, World!']);
                        }
                    }`}
                />
                <h2>Nginx Load Balancing Configuration</h2>
                <TutorialsContent content={`
                    http {
                        upstream backend {
                            server backend1.example.com;
                            server backend2.example.com;
                            server backend3.example.com;
                        }

                        server {
                            listen 80;
                            location / {
                                proxy_pass http://backend;
                            }
                        }
                    }`}
                />
                <h2>MySQL Load Balancing with ProxySQL</h2>
                <TutorialsContent content={`
                    INSERT INTO mysql_servers (hostname, port) VALUES ('db1.example.com', 3306);
                    INSERT INTO mysql_servers (hostname, port) VALUES ('db2.example.com', 3306);
                    INSERT INTO mysql_servers (hostname, port) VALUES ('db3.example.com', 3306);

                    LOAD MYSQL SERVERS TO RUNTIME;
                    SAVE MYSQL SERVERS TO DISK;`}
                />
                <h2>React Component with Framer Motion Animation</h2>
                <TutorialsContent content={`
                    import React from 'react';
                    import { motion } from 'framer-motion';

                    const Box = () => {
                        return (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    backgroundColor: 'blue',
                                    margin: '20px'
                                }}
                            >
                            </motion.div>
                        );
                    };

                    export default Box;
                `}
                />
                <h2>React Microfrontend with Single-SPA</h2>
                <TutorialsContent content={`
                    import { registerApplication, start } from 'single-spa';

                    // Register a microfrontend application
                    registerApplication(
                    'app1',
                    () => import('app1/main.js'),
                    (location) => location.pathname.startsWith('/app1')
                    );

                    // Start the single-spa orchestrator
                    start();
                `}
                />
            </div>
        </>
    )

}