import React, { useState, useEffect } from 'react';
import '../Styles/Home//Carousel.css'; // You can create a separate CSS file for styling

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import mysneaker from '../imgz/sneaker.jpg'
// import mysneaker2 from '../imgz/happy customers/evidence.jpg'
// import mysneaker3 from '../imgz/sneaker3.jpg'
// import mysneaker4 from '../imgz/sneaker4.jpg'
// import mysneaker5 from '../imgz/sneaker5.jpg'
// import mysneaker6 from '../imgz/mysneaker6.jpg'

const CarouselItem = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const shoesize = 250;
  const button_text='View Details'
  return (
    <>
      <div className="container component">

        <div className='row border'>
          <div className='col-12'>
            <h2 className='title sm_txt_20'>  Latest Solutions</h2>
            <div className='mb-3' style={{ width: '40%', textAlign: 'center', margin: ' auto', fontSize: '13px' }}>  Gain valuable insights into your sales performance. Analyze trends, identify top-selling products/services, and make data-driven decisions. </div>
          </div>

        
        </div>
      </div>
    </>
  )

};

export default CarouselItem;
