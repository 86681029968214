import React from 'react'
import { Container, Row } from 'react-bootstrap'

function MailCow() {
    return (
        <Container>
            <Row>
                <h1>Preparations</h1>
                <p>Before you can start installing Mailcow, you need to make some preparations, which primarily involve adjusting the DNS settings of the domain you want to use for sending and receiving emails. To do this, follow the steps below:</p>
                <p>
                    <ol>
                        <li>The hostname of your server should be &quot;mail&quot;, so the FQDN should be &quot;mail.testdomain.com&quot;.</li>
                        <li>Add an A record for the subdomain &quot;mail&quot; (mail.testdomain.com) and let this point to the IP address of the mail server.</li>
                        <li>Add an MX record for your domain and set the value to the mail subdomain you just created (mail.testdomain.com) with priority 10.</li>
                        <li>Define a CNAME record for the subdomains &quot;autodiscover&quot; as well as &quot;autoconfig&quot; and set the destination of both CNAME records to the mail subdomain as well (mail.testdomain.com).</li>
                        <li>Add a TXT record for your domain and set the value to &quot;v=spf1 mx ~all&quot;, to allow the server specified in the MX record (the mail server where Mailcow will be installed) to send e-mails with your domain as the sender domain. The &quot;~all&quot; means that other servers are not allowed to send e-mails from your domain, but these e-mails will still be delivered (softfail).</li>
                        <li>Define a PTR record (Reverse DNS) for the IP address of your server and set the value to the FQDN of your server (&quot;mail.testdomain.com&quot;). You can set this PTR record directly in the web interface of any good hoster like Contabo. For some providers, you have to write an e-mail or open a support ticket.</li>
                    </ol>
                </p>

                <h1>Mailcow Installation</h1>
                <p>If you haven&apos;t already done so, download the program &quot;PuTTY&quot;.</p>
                <p>Connect to your root server or VPS/vServer via SSH using PuTTY. To do this, open PuTTY and enter the domain or IP address of your server in the text box named &quot;Host Name (or IP address)&quot;. Then click the &quot;OK&quot; button below.</p>
                <p>Update your package lists with the command <p className='code'><span className='color1'>apt update</span></p>.</p>
                <p>Now install any available updates of the packages already installed on your server using the command <p className='code'><span className='color1'>apt upgrade -y</span></p>.</p>
                <p>Next, install the packages needed for future installations in this tutorial by executing the following command: <p className='code'><span className='color1'>apt install curl nano git apt-transport-https ca-certificates gnupg2 software-properties-common -y</span></p></p>
                <p>Install Docker by completing the following steps:</p>
                <p>
                    <ol>
                        <li>Use the following command to add the key needed for the Docker repository:<br />
                            For Debian: <p className='code'><span className='color1'>curl -fsSL https://download.docker.com/linux/debian/gpg | gpg --dearmor -o /usr/share/keyrings/docker-archive-keyring.gpg</span></p><br />
                            For Ubuntu: <p className='code'><span className='color1'>curl -fsSL https://download.docker.com/linux/ubuntu/gpg | gpg --dearmor -o /usr/share/keyrings/docker-archive-keyring.gpg</span></p>
                        </li>
                        <li>Add the repository needed to install Docker by executing this command:<br />
                            For Debian: <p className='code'><span className='color1'>echo &quot;deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/docker-archive-keyring.gpg] https://download.docker.com/linux/debian $(lsb_release -cs) stable&quot; | tee /etc/apt/sources.list.d/docker.list</span></p><br />
                            For Ubuntu: <p className='code'><span className='color1'>echo &quot;deb [arch=$(dpkg --print-architecture) signed-by=/usr/share/keyrings/docker-archive-keyring.gpg] https://download.docker.com/linux/ubuntu $(lsb_release -cs) stable&quot; | tee /etc/apt/sources.list.d/docker.list</span></p>
                        </li>
                        <li>Now update your package lists again with the command <p className='code'><span className='color1'>apt update</span></p>.</li>
                        <li>Install Docker with the following command: <p className='code'><span className='color1'>apt install docker-ce docker-ce-cli -y</span></p>.</li>
                        <li>Now download Docker Compose with the command <p className='code'><span className='color1'>curl -L https://github.com/docker/compose/releases/download/v$(curl -Ls https://www.servercow.de/docker-compose/latest.php)/docker-compose-$(uname -s)-$(uname -m) &gt; /usr/local/bin/docker-compose</span></p>.</li>
                        <li>Then use the command <p className='code'><span className='color1'>chmod +x /usr/local/bin/docker-compose</span></p> to assign the required execution permissions to Docker Compose.</li>
                        <li>Run the command <p className='code'><span className='color1'>cd /opt</span></p> to change to the directory &quot;/opt&quot;.</li>
                        <li>Download Mailcow/the master branch of the corresponding repository with the following command: <p className='code'><span className='color1'>git clone https://github.com/mailcow/mailcow-dockerized</span></p>.</li>
                        <li>Execute the command <p className='code'><span className='color1'>cd mailcow-dockerized</span></p> to go to the Mailcow directory.</li>
                        <li>Now the configuration file for Mailcow needs to be generated. Use the command <p className='code'><span className='color1'>./generate_config.sh</span></p>. You will then be asked for an FQDN. Enter the FQDN of your server (&quot;mail.testdomain.com&quot;) and press enter. You can skip the question about the time zone by pressing enter, because the correct time zone should already be set by default. Finally, select the branch &quot;master&quot; by typing &quot;1&quot; and pressing enter.</li>
                        <li>The configuration file is now generated. You can optionally customize it with the command <p className='code'><span className='color1'>nano mailcow.conf</span></p>, if you, for example, already have a web server installed and you therefore have to use different ports (&quot;HTTP_PORT&quot; and &quot;HTTPS_PORT&quot;) for the Mailcow web server. You can also change the value of the parameter &quot;SKIP_LETS_ENCRYPT&quot; to &quot;y&quot; if you don&apos;t want an SSL certificate to be automatically requested/issued from Let&apos;s Encrypt. If you made changes to this configuration, save them by pressing CTRL + X, then hit the &quot;Y&quot; key and press enter.</li>
                        <li>Download the images needed for Mailcow with the following command: <p className='code'><span className='color1'>docker-compose pull</span></p>.</li>
                        <li>Now start the Mailcow container by executing the command <p className='code'><span className='color1'>docker-compose up -d</span></p>.</li>
                        <li>Mailcow automatically requests a Let&apos;s Encrypt SSL certificate for the domain you specified as the hostname (&quot;acme-mailcow&quot; container), unless this feature has been explicitly disabled within the configuration file. Thus, you can visit the Mailcow web interface via HTTPS. To automatically redirect HTTP requests to HTTPS, perform the following steps:</li>
                        <li>Create an Nginx configuration file using the command <p className='code'><span className='color1'>nano /opt/mailcow-dockerized/data/conf/nginx/redirect.conf</span></p>.</li>
                        <li>Add the following content to this configuration file:</li>
                        <li>Save the changes to this configuration file by pressing CTRL + X, then hit the &quot;Y&quot; key followed by enter.</li>
                        <li>Now restart Nginx. To do this, use the command <p className='code'><span className='color1'>docker-compose restart nginx-mailcow</span></p>. All HTTP requests will now be automatically redirected to HTTPS.</li>
                    </ol>
                </p>

                <h1>Mailcow Configuration</h1>
                <p>Open the Mailcow web interface under the domain of your server in your web browser via HTTPS. If you have changed the web server port in the configuration file, you must now of course specify this port (e.g. &quot;https://mail.testdomain.com:4433&quot;).</p>
                <p>
                    <ol>
                        <li>Log in with the username &quot;admin&quot; and the default password &quot;moohoo&quot;.</li>
                        <li>Click &quot;System&quot; at the top menu and then &quot;Configuration&quot;.</li>
                        <li>Click on &quot;Edit&quot; right next to the &quot;admin&quot; user under &quot;Edit administrator details&quot;.</li>
                        <li>Change the password of the administrator user. You can also change the username if you want. After you&apos;ve done this, click on the &quot;Save changes&quot; button.</li>
                    </ol>
                </p>

                <h1>DKIM Configuration</h1>
                <p>The DKIM configuration is essential for email authentication:</p>
                <p>
                    <ol>
                        <li>Log in to the Mailcow web interface and click on &quot;System&quot; at the top menu. Then click on &quot;Configuration&quot;.</li>
                        <li>Click on the &quot;Options&quot; tab and then on &quot;ARC/DKIM keys&quot;.</li>
                        <li>For each configured domain, a DKIM key with the selector &quot;dkim&quot; and a key length of 2048 bits is already generated automatically. Copy the contents of the text box</li>
                        <li>Finally, add a TXT record for &quot;dkim._domainkey.testdomain.com&quot; (matching the DKIM selector) in your domain&apos;s DNS settings and set the previously copied content from the text box as the value of this TXT record.</li>
                    </ol>
                </p>

                <h1>Using the Webmail Client &quot;SOGo&quot;</h1>
                <p>Of course, you can use mail clients like Thunderbird or Outlook, but Mailcow also offers its own webmail client. Using this webmail client, you can also read your e-mails directly in the browser:</p>
                <p>In the top menu of the Mailcow web interface, click on &quot;Apps&quot; and then click on &quot;Webmail&quot; or open the webmail client directly by appending &quot;/SOGo&quot; to the domain of your server.</p>
                <p>Now log in to the webmail client. Enter the complete e-mail address of the mailbox as the user name and enter the corresponding password.</p>
                <p>You should already see an e-mail in the inbox, because a personal calendar was automatically created for you. Now you can use the SOGo webmail client.</p>

                <h1>Setting up a Mail Client (e.g. Thunderbird)</h1>
                <p>Of course, you can also add your mailboxes to traditional mail clients such as Thunderbird, Outlook, or Apple Mail. Usually, you only need to specify the username (e-mail address of the mailbox) and the password. The server settings should be determined automatically. If this doesn&apos;t work, use the following server settings:</p>

                <p>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Server</th>
                                <th>Protocol</th>
                                <th>Server Hostname</th>
                                <th>Port</th>
                                <th>SSL</th>
                                <th>Authentication</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Incoming</td>
                                <td>IMAP</td>
                                <td>FQDN of the mail server (usually mail.testdomain.com)</td>
                                <td>993</td>
                                <td>SSL/TLS</td>
                                <td>Normal password</td>
                            </tr>
                            <tr>
                                <td>Outgoing</td>
                                <td>SMTP</td>
                                <td>FQDN of the mail server (usually mail.testdomain.com)</td>
                                <td>587</td>
                                <td>STARTTLS</td>
                                <td>Normal password</td>
                            </tr>
                        </tbody>
                    </table>

                </p>
            </Row>
        </Container>
    )
}

export default MailCow