import React, { useRef, useState } from 'react'


import '../Tutorials.css'
import { Helmet } from 'react-helmet'
import Whatsapp from '../../pages/WhatsappLink/Whatsapp'
import { Container } from 'react-bootstrap'
function Microfrontend() {

    /* #region  REferences on click */
    const textRef1 = useRef(null);
    const textRef2 = useRef(null);
    const textRef3 = useRef(null);
    const textRef4 = useRef(null);
    const textRef5 = useRef(null);

    const textRef6 = useRef(null);
    const textRef7 = useRef(null);
    const textRef8 = useRef(null);
    const textRef9 = useRef(null);
    const textRef10 = useRef(null);
    const textRef11 = useRef(null);
    const textRef12 = useRef(null);
    const textRef13 = useRef(null);
    const textRef14 = useRef(null);
    const textRef15 = useRef(null);
    const textRef16 = useRef(null);
    const textRef17 = useRef(null);
    const textRef18 = useRef(null);
    const textRef19 = useRef(null);

    const textRef20 = useRef(null);
    const textRef21 = useRef(null);
    const textRef22 = useRef(null);
    const textRef23 = useRef(null);
    const textRef24 = useRef(null);
    const textRef25 = useRef(null);
    const textRef26 = useRef(null);
    const textRef27 = useRef(null);
    const textRef28 = useRef(null);

    /* #endregion */

    const [isVisible, setIsVisible] = useState(false);
    const [copyText, setCopyText] = useState('Copy')
    const copyToClipboard = (ref) => {
        if (ref.current) {
            const text = ref.current.innerText;
            navigator.clipboard.writeText(text).then(() => {
                setIsVisible(true);
            }).catch(err => {
                console.error('Failed to copy text: ', err);
            });
        }

    }



    return (
        <>
            <Helmet>
                <title>Tutorials - CODEGURU Systems</title>
                <meta name="description" content="Tutorials - CODEGURU" />
                <meta name="keywords" content="Java programming, Java development, Java tutorials, Java guides, 
                Java code examples, Java best practices, Java frameworks, Spring framework, Hibernate, Java EE, 
                Java SE, JavaFX, Java Swing, Java concurrency, Java multithreading, Java streams, Java collections,
                 Java design patterns, Java debugging, Java performance tuning, Java security, Java testing, JUnit, 
                 Mockito, Java web development, Java RESTful services, Java microservices, Java deployment, Java build tools,
                  Maven, Gradle, Java IDE, IntelliJ IDEA, Eclipse, NetBeans, Java garbage collection, Java memory management, 
                  Java annotations, Java lambda expressions, Java functional programming, Java 8, Java 11, Java 17, DevOps, 
                  DevOps tools, CI/CD, continuous integration, continuous delivery, Jenkins, Git, GitHub, GitLab, Docker,
                   Kubernetes, containerization, infrastructure as code, Terraform, Ansible, Puppet, Chef, monitoring,
                    Prometheus, Grafana, DevOps best practices, DevOps automation, cloud computing, AWS, Azure, Google Cloud,
                     cloud deployment, DevOps pipelines, build automation, deployment automation, configuration management, 
                     DevOps culture, DevOps practices, ReactJS, React development, React tutorials, React guides,
                      React components, React hooks, React state management, React context API, Redux, React Router, 
                      React performance optimization, React testing, Jest, Enzyme, React best practices, 
                      React patterns, React design patterns, React project structure, React debugging, React dev tools, 
                      React Native, React and TypeScript, React and GraphQL, React lifecycle methods,
                       React functional components, React class components, React props, React state, React context,
                        JSX, React virtual DOM, single-page applications, front-end development, web development with React"/>

            </Helmet>
            <Whatsapp />
            <Container fluid className='mt-5 '>

                <h1>Micro Frontend in Reactjs</h1>
                <h2> Micro Frontend</h2>
                <p>
                    The term micro-frontend extends the concept of microservices to the frontend; a micro-frontend is to the frontend what microservices are to the backend. Essentially, the goal of a micro-frontend is to treat a web app as a composition of features owned by different, independent teams with different missions; each team works end-to-end, from databases to the UI.
                </p>
                <p>
                    Module Federation is a feature of Webpack 5 that allows multiple independent builds to form a single application. Each build acts as a container and exposes code to be consumed by other containers. Setting up Module Federation for two React.js app
                </p>
                <h2>Set Up the Host Application</h2>
                <p className='code'>

                    <span ref={textRef1}>
                        <span className='color1'>npx create-react-app host-app </span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef1)}>Copy</button>
                </p>
                <p>Go to the application folder</p>
                <p className='code'>

                    <span ref={textRef2}>
                        <span className='color1'>cd </span>
                        <span className='colorWhite'>host-app</span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef2)}>Copy</button>
                </p>

                <p>And now run the below command to install webpack</p>

                <p className='code'>

                    <span ref={textRef3}>
                        <span className='color1'>npm </span>
                        <span className='colorWhite'> install webpack webpack-cli webpack-dev-server html-webpack-plugin babel-loader @babel/core @babel/preset-env @babel/preset-react --save-dev                        </span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef3)}>Copy</button>
                </p>
                <p>Create and configure webpack.config.js:</p>

                <p className='code'>
                    <pre>
                        {/* <code> */}
                        <span ref={textRef4}>
                            <span><span className='color1'>module.exports = &#123;</span></span><br />
                            <span>    <span className='color1'>mode:</span> 'development',</span><br />
                            <span>    <span className='color1'>entry:</span> './src/index.js',</span><br />
                            <span>    <span className='color1'>devServer:</span> &#123;</span><br />
                            <span>        <span className='color1'>contentBase:</span> path.join(__dirname, 'dist'),</span><br />
                            <span>        <span className='color1'>port: 3000,</span><br />
                                <span>    &#125;,</span><br />
                                <span>    <span className='color1'>output:</span> &#123;</span><br />
                                <span>        <span className='color1'>publicPath:</span> 'http://localhost:3000/',</span><br />
                                <span>    &#125;,</span><br />
                                <span>    <span className='color1'>module:</span> &#123;</span><br />
                                <span>        <span className='color1'>rules:</span> [</span><br />
                                <span>            &#123;</span><br />
                                <span>                <span className='color1'>test:</span> /\\.jsx?$/,&nbsp;</span><br />
                                <span>               exclude: /node_modules/,&nbsp;</span><br />
                                <span>                <span className='color1'>use:</span> &#123;</span><br />
                                <span>                    <span className='color1'>loader:</span> 'babel-loader',&nbsp;</span><br />
                                <span>                    <span className='color1'>options:</span> &#123;</span><br />
                                <span>                        <span className='color1'>presets:</span> ['@babel/preset-env', '@babel/preset-react'],&nbsp;</span><br />
                                <span>                    &#125;,&nbsp;</span><br />
                                <span>                &#125;,&nbsp;</span><br />
                                <span>            &#125;,&nbsp;</span><br />
                                <span>        ],&nbsp;</span><br />
                                <span>    &#125;,&nbsp;</span><br />
                                <span>    <span className='color1'>plugins:</span> [&nbsp;</span><br />
                                <span>       new HtmlWebpackPlugin(&#123;</span><br />
                                <span>            <span className='color1'>template:</span> './public/index.html',&nbsp;</span><br />
                                <span>        &#125;),&nbsp;</span><br />
                                <span>        new ModuleFederationPlugin(&#123;</span><br />
                                <span>            <span className='color1'>name:</span> 'host',&nbsp;</span><br />
                                <span>            <span className='color1'>remotes:</span> &#123;</span><br />
                                <span>                <span className='color1'>remote:</span> 'remote@http://localhost:3001/remoteEntry.js',&nbsp;</span><br />
                                <span>            &#125;,&nbsp;</span><br />
                                <span>            <span className='color1'>shared:</span> &#123; react: &#123; singleton: true &#125;, 'react-dom': &#123; singleton: true &#125; &#125;,&nbsp;</span><br />
                                <span>        &#125;),&nbsp;</span><br />
                                <span>    ],&nbsp;</span><br />
                                <span>&#125;;</span></span>
                            {/* </code> */}
                        </span>
                    </pre>


                    <button onClick={() => copyToClipboard(textRef4)}>Copy</button>
                </p>
                <p>Update src/index.js:</p>


                <p className='code'>
                    <pre>
                        <span ref={textRef5}>
                            <code>
                                <span><span className='color1'>import</span> <span className='color1'>React </span> <span className='color2'>from</span> 'react';</span><br />
                                <span><span className='color1'>import</span> <span className='color1'>ReactDOM </span><span className='color2'>from</span> 'react-dom';</span><br />
                                <span><span className='color1'>import</span> <span className='color1'>App</span> <span className='color2'>from</span> './App';</span><br /><br />
                                <span><span className='color1'>ReactDOM.render</span>(</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;React.StrictMode&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;App /&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/React.StrictMode&gt;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className='color3'>document</span>.<span className='color1'>getElementById</span>('root')</span><br />
                                <span>);</span>
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef5)}>Copy</button>
                </p>
                <p>Create src/App.js:</p>

                <p className='code'>
                    <pre>
                        <span ref={textRef6}>
                            <code>
                                <span><span className='color1'>import</span> <span className='color2'>React</span> from 'react';</span><br /><br />
                                <span>const <span className='color1'>App</span> = () =&gt; (</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h1&gt;Host Application&lt;/h1&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;RemoteComponent /&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;</span><br />
                                <span>);</span><br /><br />
                                <span><span className='color1'>const</span> <span className='color2'>RemoteComponent = React.lazy</span>(() =&gt; import('remote/RemoteComponent'));</span><br /><br />
                                <span><span className='color1'>export default</span> <span className='color2'>App</span>;</span>
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef6)}>Copy</button>
                </p>
                <p>Add a basic Babel configuration .babelrc:</p>
                <p className='code'>
                    <pre>
                        <span ref={textRef7}>
                            <code>
                                <span>&#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className='color1'>"presets"</span>: [<span className='color2'>"@babel/preset-env"</span>, <span className='color2'>"@babel/preset-react"</span>]</span><br />
                                <span>&#125;</span>
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef17)}>Copy</button>
                </p>
                <p className='code'>
                    <span ref={textRef18}>
                        <span>  npm start</span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef18)}>Copy</button>
                </p>
                <h2> Set Up the Remote Application</h2>
                <p>Now that we have create the host app, it is the time to create the remote app,
                    this will dispose the component to the host app.
                </p>
                <p className='code'>
                    <span ref={textRef19}>
                        <span>npx </span>
                        <span>create-react-app remote-app </span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef19)}>Copy</button>
                </p>
                <p className='code'>
                    <span ref={textRef20}>
                        <span className='color1'>   cd</span> <span> remote-app </span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef20)}>Copy</button>
                </p>
                <p>Create a new React app: </p>

                <p className='code'>
                    <span ref={textRef21}>
                        <span>npx create-react-app remote-app</span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef21)}>Copy</button>
                </p>
                <p className='code'>
                    <span ref={textRef22}>
                        <span className='color1'>cd remote-app</span>
                    </span>
                    <button onClick={() => copyToClipboard(textRef22)}>Copy</button>
                </p>

                <p>Install Webpack 5 and related dependencies:</p>

                <p className='code'>
                    <span ref={textRef23}>npm install webpack webpack-cli webpack-dev-server html-webpack-plugin babel-loader @babel/core @babel/preset-env @babel/preset-react --save-dev
                    </span>
                    <button onClick={() => copyToClipboard(textRef23)}>Copy</button>
                </p>
                <p>Create and configure webpack.config.js:</p>

                <p className='code'>
                    <pre>
                        <span ref={textRef24}>
                            <code>
                                <span><span className="color2">const</span> path = require('path');</span><br />
                                <span><span className="color2">const</span> <span className="color1">HtmlWebpackPlugin</span> = require('html-webpack-plugin');</span><br />
                                <span><span className="color2">const</span> <span className="color1">ModuleFederationPlugin</span> = require('webpack').container.ModuleFederationPlugin;</span><br /><br />
                                <span>module.exports = &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">mode:</span> 'development',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">entry:</span> './src/index.js',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">devServer:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">contentBase:</span> path.join(__dirname, 'dist'),</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">port:</span> 3001,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">output:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>publicPath:</span> 'http://localhost:3001/',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">module:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">rules:</span> [</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">test:</span> /\\.jsx?$/,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">exclude:</span> /node_modules/,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">use:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">loader:</span> 'babel-loader',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">options:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">presets:</span> ['@babel/preset-env', '@babel/preset-react'],</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;],</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">plugins:</span> [</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;new HtmlWebpackPlugin(&#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">template:</span> './public/index.html',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;),</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;new ModuleFederationPlugin(&#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">name:</span> 'remote',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">filename:</span> 'remoteEntry.js',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">exposes:</span> &#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'./RemoteComponent': './src/RemoteComponent',</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="color1">shared:</span> &#123; react: &#123; singleton: true &#125;, 'react-dom': &#123; singleton: true &#125; &#125;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#125;),</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;],</span><br />
                                <span>&#125;;</span>
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef24)}>Copy</button>
                </p>
                <p>Update src/index.js:</p>
                <p className='code'>
                    <pre>
                        <span ref={textRef25}>
                            <code>
                                <span><span className='color1'>import</span> <span className='color2'>React </span> <span className='color1'>from</span> 'react';</span><br />
                                <span><span className='color1'>import</span> <span className='color2'>ReactDOM </span><span className='color1'>from</span> 'react-dom';</span><br />
                                <span><span className='color1'>import</span> <span className='color2'>App</span> <span className='color1'>from</span> './App';</span><br /><br />
                                <span><span className='color1'>ReactDOM.render</span>(</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;React.StrictMode&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;App /&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/React.StrictMode&gt;,</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className='color3'>document</span>.<span className='color1'>getElementById</span>('root')</span><br />
                                <span>);</span>
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef25)}>Copy</button>
                </p>
                <p>Create src/App.js:</p>
                <p className='code'>
                    <pre>
                        <span ref={textRef26}>
                            <code>
                                <span><span className='color1'>import </span><span className='color2'>React</span> from 'react';</span><br />
                                <span><span className='color1'>import</span><span className='color2'> RemoteComponent</span> from './RemoteComponent';</span><br /><br />
                                <span>const App = () =&gt; (</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h1&gt;Remote Application&lt;/h1&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;RemoteComponent /&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;</span><br />
                                <span>);</span><br /><br />
                                <span><span className='color1'>export</span> default App;</span><br /><br />
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef26)}>Copy</button>
                </p>
                <p>Create src/RemoteComponent.js:</p>
                <p className='code'>
                    <pre>
                        <span ref={textRef27}>
                            <code>
                                <span><span className='color1'>import</span> <span className='color2'>React</span> from 'react';</span><br /><br />
                                <span>const <span className='color2'>RemoteComponent</span> = () =&gt; (</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;div&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h2&gt;This is a remote component!&lt;/h2&gt;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/div&gt;</span><br />
                                <span>);</span><br /><br />
                                <span><span className='color1'>export</span> default RemoteComponent;</span><br /><br />
                            </code>
                        </span>
                    </pre>
                    <button onClick={() => copyToClipboard(textRef27)}>Copy</button>
                </p>
                <p className='code'>
                    <pre >
                        <span ref={textRef28}>
                            <code>
                                <span>&#123;</span><br />
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;<span className='color3'>"presets"</span>: ["@babel/preset-env", "@babel/preset-react"]</span><br />
                                <span>&#125;</span>
                            </code >
                        </span>
                    </pre >
                    <button onClick={() => copyToClipboard(textRef28)}>Copy</button>
                </p >
                <p>Start both of the applications</p>
                <p className='mt-3'>Open your browser and go to <b>http://localhost:3000</b>. You should see the host application, and it should successfully
                    import and display the remote component from the remote application.
                </p>
                <p >
                    This setup demonstrates we have seen the basic configuration for using Webpack Module Federation to share components between two React applications.
                    Adjust the configurations and components as needed to fit your actual use case.
                </p>
            </Container >

        </>
    )
}

export default Microfrontend