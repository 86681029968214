import React from 'react';
import CodeSnippet from './CodeSnippet';

const TutorialsContent = (props) => {
  const javaCode = `${props.content}`;

  return (
    <div>
      <CodeSnippet code={javaCode} />
    </div>
  );
};

export default TutorialsContent;